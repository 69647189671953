import {
  GetFullProfile_profiles,
  GetFullProfile_profiles_owner,
} from '@/operations/GetFullProfile'
import { GetProfileByOwnerId_profiles } from '@/operations/GetProfileByOwnerId'
import { ReactNode } from 'react'

import { FeatureFlags } from '../feature_flags/hooks/useFeatureFlags'
import { BelonglyUser } from '../hooks/useProfile'
import { Actions } from './utils'

export const Roles = {
  User: 'user',
  Approved: 'approved',
  Member: 'member',
  SystemAdmin: 'system_admin',
  ClientPulse: 'client_pulse',
}

export interface ShowOrHideProps {
  alternativeComponent?: ReactNode
  behavior: 'ShowOrHide'
}

export type ModalProps = { title: string; content: string }

export interface ShowModalProps {
  behavior: 'ShowModal'
  modal?: ModalProps
}

export interface DisableProps {
  behavior: 'Disable'
  tooltip?: string
  tooltipPlace?: string
}

export type PermissionWrapperProps = {
  children: React.ReactElement
  className?: string
  action: keyof typeof Actions | (keyof typeof Actions)[]
  variables?: any
  isNavItem?: boolean
} & (ShowModalProps | ShowOrHideProps | DisableProps)

export interface ActionParams {
  user: BelonglyUser | GetFullProfile_profiles_owner | undefined | null
  feature_flags: FeatureFlags
  variables?: any
  profile?: GetProfileByOwnerId_profiles | GetFullProfile_profiles | undefined
}

export type CheckPermissionsAndRoll = {
  rol?: string
} & ActionParams
