import {
  ModalProps,
  PermissionWrapperProps,
} from '@/components/permission_wrapper/types'
import { Actions } from '@/components/permission_wrapper/utils'
import { Tooltip } from '@/components/ui/tooltip'
import { propOr } from 'ramda'
import { ReactNode, cloneElement, useEffect, useMemo, useState } from 'react'

import useFeatureFlags from 'components/feature_flags/hooks/useFeatureFlags'
import { useProfile } from 'components/hooks'
import { Loading, Modal } from 'components/ui'

function PermissionWrapper({
  children,
  className,
  behavior,
  variables,
  isNavItem = false,
  ...props
}: PermissionWrapperProps) {
  const [showWarning, toggleWarning] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { user, profile, loading: profileLoading } = useProfile()

  const {
    featureFlags: { instant_access: instantAccessIsOn, strict_permissions },
    loading: featureFlagsLoading,
  } = useFeatureFlags()

  // Only check permissions when both profile and feature flags are loaded
  const hasPermissions = useMemo(() => {
    if (profileLoading || featureFlagsLoading) {
      // For navbar items, assume permission granted while loading to avoid flashing
      if (isNavItem) return true
      return null
    }

    return Array.isArray(props.action)
      ? !props.action
          .map((action) =>
            Actions[action]({
              user,
              feature_flags: { strict_permissions },
              variables,
              profile,
            }),
          )
          .includes(false)
      : Actions[props.action]({
          user,
          feature_flags: { strict_permissions },
          variables,
          profile,
        })
  }, [
    props.action,
    user,
    strict_permissions,
    variables,
    profile,
    profileLoading,
    featureFlagsLoading,
    isNavItem,
  ])

  // Only restrict if not loading and user doesn't have permissions
  const shouldRestrict = instantAccessIsOn && hasPermissions === false

  useEffect(() => {
    // Only set isLoading to false when we have completed the permission check
    if (hasPermissions !== null) {
      setIsLoading(false)
    }
  }, [hasPermissions, profileLoading, featureFlagsLoading])

  // For navbar items, don't show loading indicator
  // For other components, show loading while profile or feature flags are loading
  if (
    !isNavItem &&
    (profileLoading || featureFlagsLoading || hasPermissions === null)
  ) {
    return (
      <div className="flex justify-center items-center">
        <Loading />
      </div>
    )
  }

  if (behavior === 'ShowModal') {
    const { title, content }: ModalProps = propOr(
      { title: 'Warning', content: 'You are not a member.' },
      'modal',
      props,
    )

    if (shouldRestrict) {
      return (
        <>
          {cloneElement(children, {
            onClick: () => toggleWarning(true),
          })}
          <Modal
            isOpen={showWarning}
            onClose={() => toggleWarning(false)}
            title={title}
          >
            {content}
          </Modal>
        </>
      )
    }

    return <>{children}</>
  }

  if (behavior === 'Disable') {
    const tooltip: string = propOr('Become a member', 'tooltip', props)

    if (shouldRestrict) {
      return (
        <>
          <Tooltip content={tooltip}>
            {cloneElement(children, {
              onClick: () => {},
              'data-tip': true,
              'data-for': tooltip,
            })}
          </Tooltip>
        </>
      )
    }
  }

  const alternativeComponent: ReactNode = propOr(
    <></>,
    'alternativeComponent',
    props,
  )

  // For navbar items during loading, just don't show them instead of showing the alternative
  if (shouldRestrict) {
    return <>{alternativeComponent}</>
  }

  // For navbar items, never show the loading indicator
  if (isLoading && !isNavItem) {
    return (
      <div className="flex justify-center items-center">
        <Loading />
      </div>
    )
  }

  return <>{children}</>
}

export default PermissionWrapper
