import {
  check_business_profile_followers_insert_permissions,
  check_business_profiles_insert_permissions,
  check_invitations_insert_permissions,
  check_liked_comments_insert_permissions,
  check_liked_posts_delete_permissions,
  check_liked_posts_insert_permissions,
  check_member_invitations_insert_permissions,
  check_message_group_insert_permissions,
  check_opportunities_insert_permissions,
  check_opportunity_comments_insert_permissions,
  check_opportunity_respondants_insert_permissions,
  check_post_comments_insert_permissions,
  check_post_shares_insert_permissions,
  check_posts_insert_permissions,
} from 'permissions'

import { ActionParams, Roles } from '../types'
import {
  checkClientPulsePermissions,
  checkPermissions,
} from './permission_checks'

export const Actions = {
  InsertPost: ({ user, feature_flags, variables }: ActionParams) =>
    check_posts_insert_permissions(
      feature_flags,
      {
        business: variables,
        author: { owner_id: user?.id },
      },
      user,
    ),
  UploadPhoto: ({ user, feature_flags, variables }: ActionParams) =>
    checkPermissions({ user, feature_flags, rol: variables.rol }),

  FollowBusinessPage: ({ user, feature_flags, variables }: ActionParams) =>
    check_business_profile_followers_insert_permissions(
      variables,
      feature_flags,
      user,
    ),
  SendMessageToBusinessPage: ({ user, feature_flags }: ActionParams) =>
    check_message_group_insert_permissions(feature_flags, null, user),
  SendNewMessage: ({ feature_flags, user }: ActionParams) =>
    check_message_group_insert_permissions(feature_flags, null, user),
  Connect: ({ user, feature_flags }: ActionParams) =>
    check_invitations_insert_permissions(
      feature_flags,
      {
        from_profile: user,
      },
      user,
    ),
  InviteColleagues: ({ user, feature_flags }: ActionParams) =>
    check_member_invitations_insert_permissions(
      feature_flags,
      { owner_profile: { owner_id: user?.id } },
      user,
    ),
  TakeReferral: ({ user, feature_flags, variables }: ActionParams) => {
    const referralData = {
      response_type: 'SUBMIT_PROFILE',
      from_profile: {
        owner: user,
      },
    }

    return check_opportunity_respondants_insert_permissions(
      feature_flags,
      referralData,
      user,
    )
  },
  RecommendColleague: ({ user, feature_flags, variables }: ActionParams) => {
    const referralData = {
      response_type: 'RECOMMEND',
      from_profile: {
        owner: user,
      },
      recommender_profile: {
        owner: user,
      },
    }

    return check_opportunity_respondants_insert_permissions(
      feature_flags,
      referralData,
      user,
    )
  },
  SubmitRecommendedColleague: ({
    user,
    feature_flags,
    variables,
  }: ActionParams) => {
    const referralData = {
      response_type: variables?.responseType,
      from_profile: {
        owner: user,
      },
      recommender_profile: variables?.recommenderProfile,
    }

    return check_opportunity_respondants_insert_permissions(
      feature_flags,
      referralData,
      user,
    )
  },
  AddCommentToReferral: ({ user, feature_flags }: ActionParams) => {
    const opportunityComment = {
      author: {
        owner_id: user?.id,
      },
    }
    return check_opportunity_comments_insert_permissions(
      feature_flags,
      opportunityComment,
      user,
    )
  },
  CreateReferral: ({ user, feature_flags, variables }: ActionParams) => {
    const opportunity = {
      author: {
        owner: user,
        business: variables,
      },
    }
    return check_opportunities_insert_permissions(
      feature_flags,
      opportunity,
      user,
    )
  },
  LikePost: ({ user, feature_flags }: ActionParams) => {
    const likedPost = {
      from_profile: {
        owner_id: user?.id,
      },
    }
    return (
      check_liked_posts_insert_permissions(feature_flags, likedPost, user) &&
      check_liked_posts_delete_permissions(feature_flags, likedPost, user)
    )
  },
  CommentPost: ({ user, feature_flags }: ActionParams) => {
    const postComment = {
      author: {
        owner_id: user?.id,
      },
    }
    return check_post_comments_insert_permissions(
      feature_flags,
      postComment,
      user,
    )
  },
  SharePost: ({ user, feature_flags }: ActionParams) => {
    const postShare = {
      profile: {
        owner_id: user?.id,
      },
    }
    return check_post_shares_insert_permissions(feature_flags, postShare, user)
  },
  LikeComment: ({ user, feature_flags }: ActionParams) => {
    const likedComment = {
      from_profile: {
        owner_id: user?.id,
      },
    }
    return check_liked_comments_insert_permissions(
      feature_flags,
      likedComment,
      user,
    )
  },
  ReplyComment: ({ user, feature_flags }: ActionParams) => {
    const postComment = {
      author: {
        owner_id: user?.id,
      },
    }
    return check_post_comments_insert_permissions(
      feature_flags,
      postComment,
      user,
    )
  },
  CreateBusinessPage: ({ user, feature_flags }: ActionParams) => {
    const businessProfile = {
      owner: {
        owner_id: user?.id,
        owner: user,
      },
    }
    return check_business_profiles_insert_permissions(
      businessProfile,
      feature_flags,
      user,
    )
  },
  ViewAdmin: ({ user, feature_flags }: ActionParams) =>
    checkPermissions({ user, feature_flags, rol: Roles.SystemAdmin }),
  ViewAdminProfiles: ({ user, feature_flags }: ActionParams) =>
    checkPermissions({ user, feature_flags, rol: Roles.SystemAdmin }),
  ViewAdminStats: ({ user, feature_flags }: ActionParams) =>
    checkPermissions({ user, feature_flags, rol: Roles.SystemAdmin }),
  ViewAdminProfileRoles: ({ user, feature_flags }: ActionParams) =>
    checkPermissions({ user, feature_flags, rol: Roles.SystemAdmin }),
  ViewClientPulse: ({ user, profile }: ActionParams) => {
    return (
      checkClientPulsePermissions({
        user,
        rol: Roles.SystemAdmin,
        profile,
      }) ||
      checkClientPulsePermissions({
        user,
        rol: Roles.ClientPulse,
        profile,
      }) ||
      checkClientPulsePermissions({
        user,
        rol: Roles.Member,
        profile,
      }) ||
      checkClientPulsePermissions({
        user,
        rol: Roles.Approved,
        profile,
      })
    )
  },
}
