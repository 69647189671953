import { ActionParams, CheckPermissionsAndRoll, Roles } from '../types'

export const checkPermissions = ({
  feature_flags,
  user,
  rol,
}: CheckPermissionsAndRoll) => {
  return (
    (feature_flags?.strict_permissions === true &&
      user?.roles?.includes(rol)) ||
    feature_flags?.strict_permissions === false
  )
}

export function checkClientPulsePermissions({
  user,
  rol,
  profile,
}: Omit<CheckPermissionsAndRoll, 'feature_flags'>) {
  // Make sure user and roles are defined before checking for role inclusion
  // Also ensure profile exists before checking is_ambassor
  return (
    (user?.roles &&
      Array.isArray(user.roles) &&
      rol &&
      user.roles.includes(rol)) ||
    (profile !== undefined && profile !== null && Boolean(profile?.is_ambassor))
  )
}
